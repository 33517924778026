import React, { Component } from "react";
import { Layout } from "../components/Layout/layout";
import { amplitudeTrackEvent } from "../components/utils/Amplitude";
import Wrapper from "../components/Layout/Wrapper/wrapper";
import Loading from "../components/atoms/Loading";
import Seo from "../components/utils/SEO";
import { SideSummary } from "../components/organisms/SideSummary/";
import { MessageBox } from "../components/molecules/MessageBox";
import success from "../images/icons/done_sucess.svg";
import { getCookie, removeCookie } from "../components/utils/cookies";
import { sendPurchaseEvent } from "../components/utils/dataLayer/events";
import { navigate } from "gatsby";

class ThankYou extends Component {
  constructor(props) {
    super(props);

    const orderCookie = getCookie("address_token");

    this.state = {
      orderReference: orderCookie.reference,
      applicationId: orderCookie.order,
      form: null,
      loading: true,
      application: {
        applicationName: "",
        applicationNumber: "",
        applicationPrice: {
          id: "",
          name: "",
          amount: "",
        },
      },
    };
  }

  getApplicationData = () => {
    const orderCookie = getCookie("address_token");

    let form = this.state.application;
    form.applicationName = orderCookie.application.formName;
    form.applicationNumber = orderCookie.application.formDescription;
    form.applicationId = this.state.applicationId;
    form.applicationPrice = {
      name: orderCookie.regularPrice.name,
      amount: orderCookie.regularPrice.amount,
    };

    this.setState({
      application: form,
      loading: false,
    });
  };

  pushDataLayer = (total, addons) => {
    if (typeof window !== "undefined") {
      sendPurchaseEvent(
        this.state.application.applicationName,
        this.state.orderReference,
        addons,
        this.state.application.applicationPrice.amount,
        total
      );
    } else {
      this.pushDataLayer(total, addons);
    }
  };

  cleanCookies = () => {
    this.getApplicationData();

    removeCookie("address_token");
    removeCookie("pdfId");
    removeCookie("app_id");
    removeCookie("order_status");
  };

  componentDidMount() {
    if (this.state.applicationId === undefined || this.state.applicationId === "") {
      navigate("/home");
    }

    this.cleanCookies(this.state.applicationId);

    // Keep this event here, in the same order after cleanCookies function,
    // otherwise it will not send the form data
    amplitudeTrackEvent("view_page_thankYou", {
      form: this.state.application.applicationName.toLowerCase(),
    });
  }

  setTotal(total, addons) {
    this.pushDataLayer(total, addons);
  }

  render() {
    const application = {
      ...this.state.application,
      applicationReference: this.state.orderReference,
    };
    const applicationTitle =
      application && !this.state.loading
        ? application.applicationNumber + " - " + application.applicationName + " Form"
        : undefined;

    return (
      <Layout
        headerTitle={applicationTitle}
        bgColor={`bg-grey`}
        hideFooterNavigation={true}>
        <Seo title="Thank you for trusting us" />
        <Wrapper small>
          {this.state.loading ? (
            <Loading full />
          ) : (
            <>
              <MessageBox
                title="Your payment has been made successfully!"
                content={
                  <>
                    <p>Your application is now being processed.</p>
                    <p>
                      In the next 24-72 hours, you will receive an email with confirmation of your
                      payment and a further email containing a PDF of your completed{" "}
                      {application.applicationName} application form.
                    </p>
                    <p>
                      If you have yet to receive this email and/or are unable to locate it after
                      this time, please be sure you check your spam folder.
                    </p>
                  </>
                }
                icon={success}
              />

              <SideSummary
                application={application}
                applicationReference={this.state.orderReference}
                applicationName={application.applicationNumber}
                applicationPrice={application.applicationPrice.amount}
                applicationID={application.applicationName}
                setTotal={(total, addons) => this.setTotal(total, addons)}
                thankYou
                isOpen={true}
              />
            </>
          )}
        </Wrapper>
      </Layout>
    );
  }
}

export default ThankYou;
